.item-order {
    .input-group {
        max-width: 140px;
    }
}

.order {
    margin-bottom: 30px;
}

.sidebar-offcanvas,
.breadcrumb {
    margin-top: 20px;
}


/*
 * Off Canvas
 * --------------------------------------------------
 */

@media screen and (max-width: $screen-sm - 1) {
    .toggle-offcanvas {
        margin-top: 20px;
        @include clearfix();
        p {
            margin: 0;
        }
    }
}

@include offcanvas-utils();
a.sp-current-big img,
div.sp-loading img {
    width: 100%;
    height: 100%;
}

div.other-product-thumbs {
    z-index: 2;
}