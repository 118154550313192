@import "webstore/base";

#header {
  border-bottom: 1px solid $brand-primary;
}

.homepage {
  min-height: 700px;
  background-image: url('../img/home-bg.jpg');
  // @include background(image-url("home-bg.jpg"));

  margin-left: -15px;
  margin-right: -15px;
}

.super-header {
  padding-top: 60px;
  padding-left: 160px;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: $screen-md - 1) {
    padding-top: 20px;
    padding-left: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}

.subscribe-area {
  max-width: 360px;
  margin: 160px 0 20px auto;
  text-align: right;

  label {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .form-control {
    margin-bottom: 10px;
  }
}

.cell-room {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  max-height: 400px;
}

.cell {
  display: block;
  width: 140px;
  height: 140px;
  background: $brand-primary;
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  position: relative;

  h3 {
    position: absolute;
    margin: 0;
    top: 50%;
    // @include translateY(-50%);
    transform:translateY(-50%);
    width: 140px;
    text-align: center;
    font-size: 22px;
  }
}
