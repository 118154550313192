.in-title {
  margin-top: 0;
}

#order-list {
  .order {
    .order-number {
      display: inline;
      vertical-align: middle;
      margin-right: 20px;
    }
  }
  .no-order {
    p {
      margin: 0;
    }
  }
}
.invoice-list {
  margin-top: 10px;
}

/*
 * Off Canvas
 * --------------------------------------------------
 */
 @media screen and (max-width: $screen-sm - 1) {
  .toggle-offcanvas {
    margin-top: 0;
    margin-bottom: 20px;
    @include clearfix();

    p {
      margin: 0;
    }
  }
}
@include offcanvas-utils();
