/* base imports */
// @import "compass/css3/images";
// @import "compass/css3";
// @import "compass/css3/transform";

/** colors **/
$brand-primary: #29282e;

/** screen size **/
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

/** footer **/
$footer-height: 60px;
$footer-height-sm: 30px;

/** utils **/
@mixin clearfix() {
  /** bootstrap style clearfix **/
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }

  p {
    
  }
}

@mixin offcanvas-utils() {
  @media screen and (max-width: $screen-sm - 1) {
    .row-offcanvas {
      position: relative;
      -webkit-transition: all .25s ease-out;
           -o-transition: all .25s ease-out;
              transition: all .25s ease-out;
    }

    .row-offcanvas-right {
      right: 0;
    }

    .row-offcanvas-left {
      left: 0;
    }

    .row-offcanvas-right
    .sidebar-offcanvas {
      right: -50%; 
    }

    .row-offcanvas-left
    .sidebar-offcanvas {
      left: -50%; 
    }

    .row-offcanvas-right.active {
      right: 50%; 
    }

    .row-offcanvas-left.active {
      left: 50%; 
    }

    .sidebar-offcanvas {
      position: absolute;
      top: 0;
      width: 50%; 
    }
  }
}
