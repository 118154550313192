
.expirydatefield {
  select {
    max-width: 100px;
    display: inline-block;  
  }
}

.goto-btn {
  color: white;
}

.goto-btn-form {
  display: inline-block;
}
