.login-form,
.password-reset-form,
.password-reset-from-key-form,
.add-email-form {
  max-width: 480px;
}

.password-reset-form {
  margin-bottom: 20px;
}
