@import "webstore/base";

/* pages */
.home {
  @import "webstore/home";
}
.category {
  @import "webstore/category"; 
}
.product {
  @import "webstore/product";
}
.account {
  @import "webstore/account"; 
}
.checkout {
  @import "webstore/checkout";
}
.allauth {
  @import "webstore/allauth";
}

.branding {
  h1 {
    font-family: 'Poiret One', cursive;
  }
}

#header {
  @media (max-width: $screen-md - 1) {
    .branding, .controls {
      text-align: center;
    }
  }
  @media (min-width: $screen-md) {
    .container {
      position: relative;
    }
    .controls {
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
  }
  .controls .language-bar{
    display: inline;
    form{
      display: inline;
    }
  }
}

#content {
  min-height: 680px;
}

/** sticky footer styles **/
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: $footer-height + 20px;
  @media (min-width: $screen-sm) {
    margin-bottom: $footer-height-sm + 20px;
  }
}
.footer {
  @include clearfix();
  // position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: $footer-height;
  @media (min-width: $screen-sm) {
    height: $footer-height-sm;
  }

  .footer-left {
    text-align: center;
    @media (min-width: $screen-sm) {
      float: left;
      margin-left: 20px;
    }
  }
  .footer-right {
    text-align: center;
    @media (min-width: $screen-sm) {
      float: right;
      margin-right: 20px;
    }
    form{
      display: inline
    }
    .btn-custom{
      padding: 0px 12px;
    }
  }
  .credit-link {
    margin-left: 20px;
  }
}
/** end sticky footer styles **/
