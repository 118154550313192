@import "webstore/base";

#sidebar {
  margin-top: 20px;
}

#department-list {
  .category {
    padding: 3px 10px 3px 30px;
  }
}

.search-form {
  margin-top: 20px;
  margin-bottom: 10px;
}

@media screen and (max-width: $screen-sm - 1) {
  .toggle-offcanvas {
    margin-top: 20px;
    @include clearfix();

    p {
      margin: 0;
    }
  }
}

.row-products {
  font-size: 0;
}

.col-product {
  display: inline-block;
  float: none;
  font-size: 1.4rem;
  vertical-align: top;
}

.product:hover {
    border-color: #000;
}

.product-text {
  padding-top: 4px;

  span {
    display: block;
  }
}

.subcategory-panel {
  .list-group-item {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.simple-pager {
  position: relative;

  .page-index {
    position: absolute;
    top: 6px;
    width: 100%;
    z-index: -50;
    p {
      text-align: center;
    }
  }
}

.paginator {
  text-align: center;
}

/*
 * Off Canvas
 * --------------------------------------------------
 */
@include offcanvas-utils();
